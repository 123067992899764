* {
  margin: 0;
  box-sizing: border-box;
}

.WhatPage {
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.WhatPage .div {
  scroll-snap-align: start;
  width: 100vw;
  height: 100%;
//   font-size: 5em;
//   font-family: sans-serif;
//   display: flex;
//   justify-content: center;
//   align-items: center;
}

.WhatPage .div:nth-of-type(even) {
  background-color: #fff;
  color: white;
  height: 100vh;
}

/* Scrollbar modification */
.WhatPage::-webkit-scrollbar {
  width: 0px;
}

.WhatPage::-webkit-scrollbar-track {
  background-color: transparent;
}

.WhatPage::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.WhatPage::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.hubspot-link__container sproket {
  display: none !important;
  visibility: hidden !important;
}
