.location__description {
   // width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__title {
        font-size: 300%;
        font-family: 'Mulish', sans-serif;
    }
    p {
        font-size: 120%;
        margin-bottom: 2rem;
    }
    &__list {
        font-size: 200%;
        font-family: 'Mulish', sans-serif;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 60px;
        gap: 2rem;
        margin-bottom: 2.5rem;
        background-color: gray;
        border-radius: 0px 40px 40px 20px;
        position: relative;
        height: 40px;
        color: #fff;
        width: 100%;

        &.active,
        &:hover {
            background-color: #6EBE4A;
        }

        li {
            list-style: none;
        }

        figure {
            padding: 5px 5px;
            position: absolute;
            top: -17px;
            left: -20px;

            img {
                width: 60px;
                height: 60px;
                filter: grayscale(100%);
                opacity: 0.9;
                transition: all 0.3s ease; // Agrega una transición suave al cambio de estilo
            }
        }

        &.active,
        &:hover {

            // Estilos cuando el mouse está sobre el elemento
            img {
                filter: none; // Restaura el color original de la imagen
                opacity: 1; // Restaura la opacidad original de la imagen
            }
        }
    }
}