.App-logo {
    height: 30vmin;
    pointer-events: none;
    position: relative;
  }

  .app-logo{
    height: 24vmin;
    pointer-events: none;
    // position: relative;
    right: 10px;
    bottom: -5px;
    @media screen and (max-width:1068px) {
      position: unset;
    }
  }

  .logo-text-2{
    position: relative;
    height: 24vmin;
  }

  .logo-text{
    position: relative;
    width: 30vmin;
  }



  .text-btn{
    position: absolute;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .app-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

@keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

 .mainHome__button-buttom{
     top:20vh;
    display: flex;
    position: relative;    
}