.mainProjects{
    &__title{
        font-family: 'Mulish', sans-serif;
        h2{
            font-size: 3rem;
            color: white;
            font-family: 'Mulish', sans-serif;
            @media screen and (max-width:535px) {
                font-size: 2rem;
            }
        }
        span{
            font-size: 14px;
            color: #777779;
            font-family: 'Mulish', sans-serif;
            
        }
   
    }
    &__container{
        display: flex;
        flex-direction: column;
        height: 60vh;
        align-items: center;
    justify-content: center;
    position: relative;
    @media screen and (max-width:1024px){
        height: 75vh;
        justify-content: flex-start;
        width: 90vw;
    }
    }
    &__cards{
        // height: 60vh;
        display: flex;
        flex-direction: row;
        width: 90vw;
        align-items: center;
        justify-content: center;
        transform: translateY(50px);
        flex-wrap: wrap;
        .swiper-wrapper{
            justify-content: center;
            @media screen and (max-width:910px) {
                justify-content: unset;
            }
          }
        @media screen and (max-width:535px) {
            transform: translateY(30px);
            height: 100%;         
        }
    }
}

.background-3{
    background-image: url('https://res.cloudinary.com/dgnwqr93n/image/upload/q_100/v1689218738/_MG_6572_qvrsrw.webp');
    // background-color: #6ebe4a;
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
   
}
.background-3::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.436);
}
.texto{
    font-size: 1rem;
    color: #eee;
    @media screen and (max-width:535px) {
        font-size: 0.875rem;
        
    }
}

.swiper-slide{
    @media screen and (max-width:535px) {
        height: 60vh;
        
    }
}
