.mainCircleCard {
  // height: 50vh !important;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 20px;
  align-items: center;
// height: 0px !important; Error?
// justify-content: center; // Error?
  &__title {
    font-size: 16px;
    font-weight: 700;
    color: #535353;
    font-family: 'Mulish', sans-serif;
  }
  &__text {
    font-size: 14px !important;
    font-weight: 400;
    font-family: "Mulish", sans-serif;
    height: 125px !important;
    overflow: auto;
    color: #535353;
    overflow: initial !important;
    text-indent: 0px !important;
    text-align: unset !important;
    margin-top: 0px !important;
  
  }
  &__img {
    z-index: 2;
    figure {
      img {
        border-radius: 100%;
        width: 150px;
        height: 150px;
        object-fit: cover;
        border: 2.5px solid #6ebe4a;
      }
    }
  }
  &__content {
    background-color: white;
    height: 24em !important;
    transform: translateY(-80px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    width: 270px;
    padding: 5px 20px 10px 20px;
    gap: 5px;
    justify-content: flex-end !important;
  }
  &__gestion{
    h2{
        color: white;
        font-family: 'Mulish', sans-serif;
    }
  }
}
.swiper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide{
  
    
  @media screen and (max-widthL:1045px) {
    height: 55vh;
   
  }
}
.swiper-button-prev,
.swiper-button-next{
  color: #6ebe4a !important;
}