.swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
    right: var(--swiper-pagination-right, 8px);
    left: var(--swiper-pagination-left, auto);
    top: 50%;
    transform: translate3d(-90vw, -50%, 0);
    gap: 9vh;
    @media screen and (max-width:535px) {
        transform: translate3d(0, -35vh, 0);
        gap: 20px;
        background-color: rgba(110, 190, 74, .7);
        height: 8%;
        width: 95vw;
        padding: 0px 10px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        -webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
        

    }
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width:535px) {
        gap: 0;
        flex-direction: row;
    }
    
    span{
        background-color: transparent;
        font-size: 3.5rem;
        border: 0px;
        color: white;
        @media screen and (max-width:535px) {
            font-size: 2.5rem;
        }
    }
}

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet{
width: 50px;
height: 50px;
}