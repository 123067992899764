.donationsIndicator {
  font-family: 'Mulish', sans-serif;

  &__container {
    margin-bottom: 1rem;

    h3 {
      font-size: 1.8rem;
      color: #6EBE4A;
      margin-bottom: 1rem;
      margin-top: 3rem;

    }

    h2 {
      //margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  &__background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261454/ayapel/sqdgl9g1otdeoktooccs.jpg');

    // background-color: #6ebe4a;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    z-index: 3;
    flex-wrap: wrap;
  }

  &__containerCarousel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }

  }

  &__content {
    position: absolute;
    top: 2.5rem;
    left:1.4rem;
    width: 80%;

    p {
      color: #fff;
    }
  }

  &__counter {
    font-size: 2.5rem;
    font-weight: bold;
    color: #6EBE4A;
  }

  &__ctaDonations {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__next {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  &__image {
    width: 230px;
    height: 230px;
    border-radius: 50%;
    background-size: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261449/ayapel/cpefakxd4vij3uszdoc5.jpg');

  }
}


.swiper-wrapper {
  align-items: center;
}



.swiper {
  width: 100%;
  height: 100%;
  // background: #000;
  height: 510px;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.swiper-slide-active {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.swiper-slide-next {
  width: 100%;
}





@media (max-width: 375px) {
  .swiper-container {
    width: auto !important;
  }
}

@media screen and (max-width: 1002px) {

  .donationsIndicator {
    &__container {
      margin-bottom: 1rem;
      width: 300px;
      margin-top: 2rem;
      h3 {
        font-size: 1.5rem;
        color: #6EBE4A;
        margin-top: 3rem;
       // margin-bottom: 2rem;

      }

      h2 {
        color: #6EBE4A;
        margin-bottom: 2rem;
      }
    }

    &__background {
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      z-index: 3;
      flex-wrap: wrap;
      // border: 4px solid #fff;
    }

    &__containerCarousel {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }

    }

    &__content {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      p {
        color: white;
        font-size: .9rem;
        position: absolute;
        width: 170px;
        align-items: center;
        top: -1rem;
        left: 5rem;
      }
    }

    &__counter {
      font-size: 1.6rem;
      color: #6EBE4A;
      position: absolute;
      align-items: center;
      top: -.5rem;
      left: -6rem;
      width: 100%;
    }

    &__ctaDonations {
      display: flex;
      justify-content: center;
      flex-direction: column;
      //margin-top: 3rem;
    }

    &__next {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }

    &__image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-size: cover;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261449/ayapel/cpefakxd4vij3uszdoc5.jpg');

    }
  }
}
