@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
}
html {
    --cursor-color: #fff;
    scroll-behavior: smooth;    
  }
  
  html.dark-mode {
    --cursor-color: #fff
  }

  body {
    --primaryColor: #6EBE4A;
  }

  .privacy-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primaryColor); // Usando el color morado definido
    z-index: 2;
    transition: background-color 0.5s ease; // Agrega la transición para el background-color
  }
  
  .css-1dyuqpd {
    height: 0 !important;
  
  }
  .MuiSpeedDial-root {
   bottom: 20px !important;
    left: -90% !important;
    display: flex;

  }
  .MuiFab-root {
    background-color:  var(--primaryColor) !important;
    color: #fff !important;
    font-size: 1.5rem !important;
  }
  .MuiSpeedDialIcon-root{
    display: flex;
  }

  .social-media-fixed {
    position: fixed;
    bottom: 0px;
    left: 50px;
    z-index: 9999;
  }
  

  //***********************swiper***********************************

  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    color: #6EBE4A;
    margin-right: 0px !important;   
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.ball {
  fill: #6EBE4A;
}
.header-section {
  margin-left: 3rem;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #6EBE4A;
  font-size: 2rem;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99;
  backdrop-filter: blur(8px); /* Agrega el efecto de desenfoque */
  background-color: rgba(189, 203, 183, 0.5); /* Agrega un color de fondo con transparencia */
}
.loaderMap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #6EBE4A;
  font-size: 2rem;
  width: 100%;
  height: 100%;
}
.loader-local {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #6EBE4A;
  font-size: 2rem;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(8px); /* Agrega el efecto de desenfoque */
  background-color: rgba(189, 203, 183, 0.5); /* Agrega un color de fondo con transparencia */
}

.swal2-confirm  {
  background-color: #6EBE4A !important;
}

.continuebutton {
  button { 
  background: #6EBE4A !important;
  background-color: #6EBE4A !important;
}
}