@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

.contentSocial {
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100dvh;
  &__img-Social {
    position: relative;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Mulish", sans-serif;
    @media screen and (max-width: 870px) {
      justify-content: center;
      flex-direction: column;
    }


    .content-Social {
      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      max-width: 500px;
      // position: relative;
      top: 20%;
      // width: 50%;
      color: #000;
      font-size: 24px;
      text-align: right;
      word-wrap: break-word;
      overflow: hidden;

      @media screen and (max-width: 870px) {
        top: 15%;
        justify-content: center;
        text-align: center;
      }
      @media screen and (max-width: 426px) {
        top: 15%;
        justify-content: center;
      }

      h2 {
        font-weight: 600;
        font-size: 36px;
        color: #535353;
        line-height: 49px;
        margin-top: 0;

        @media screen and (max-width: 870px) {
          font-size: 33px;
        }

        @media screen and (max-width: 775px) {
          font-size: 30px;
        }

        span {
          font-family: "Dancing Script", cursive;
          font-size: 103px;
          line-height: 123px;
          color: #535353;
          font-weight: 400;

          @media screen and (max-width: 870px) {
            font-size: 90px;
          }

          @media screen and (max-width: 775px) {
            font-size: 80px;
          }
        }
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
        color: rgb(110, 190, 74);
      }

      p {
        font-size: 18px;
        line-height: 1.2;
        @media screen and (max-width: 870px) {
          font-size: 16px;
        }
  
      }
    }

    .containerGestionSocial {
      transition: 0.5s;
      padding: 5px;
      // position: absolute;
      left: 78%;
      bottom: 43%;
      font-family: "Mulish", sans-serif;

      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 270px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;
      opacity: 1;
      transition: max-height 0.3s, opacity 0.3s;

      @media screen and (max-width: 1250px) {
        right: 75%;
      }

      @media screen and (max-width: 1070px) {
        width: 200px;
        right: 75%;
      }

      @media screen and (max-width: 930px) {
        right: 75%;
        width: 180px;
      }

      @media screen and (max-width: 870px) {
        right: 74%;
        bottom: 44%;
        width: 175px;
        height: 80px;
      }

      @media screen and (max-width: 795px) {
        right: 76%;
      }
       @media screen and (max-width:376px){
        width: 180px;
        height: 80px;
      }

      &.see {
        height: auto;
        opacity: 1;
        @media screen and (max-width: 930px) {
          height: 80px;
          height: 280px;
          position: absolute;
          top: 25%;
          left: 2.5%;
          width: 95vw;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 18px;
        }
      }

      .title {
        margin-bottom: 5px;
        font-weight: bold;
        color: black;
        @media screen and (max-width: 1050px) {
          font-size: 14px;
        }

        @media screen and (max-width: 930px) {
          font-size: 13px;
          color: black;
        }
      }

      .contentText {
        font-size: 12px;
        line-height: 16px;
        color: #000;
        @media screen and (max-width: 930px) {
          color: #000;
        }
      }
    }

    .containerGestionSocial2 {
      transition: 0.5s;
      padding: 5px;
      position: absolute;
      right: 55%;
      bottom: 43%;
      font-family: "Mulish", sans-serif;

      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 270px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;
      transition: height 0.3s;

      @media screen and (max-width: 1250px) {
        right: 50%;
      }

      @media screen and (max-width: 1070px) {
        width: 200px;
        right: 53%;
      }

      @media screen and (max-width: 930px) {
        right: 54%;
        width: 180px;
      }

      @media screen and (max-width: 870px) {
        bottom: 44%;
        width: 180px;
      }

      &.see2 {
        height: auto;
      }

      .title2 {
        margin-bottom: 5px;
        font-weight: bold;

        @media screen and (max-width: 1050px) {
          font-size: 14px;
        }

        @media screen and (max-width: 930px) {
          font-size: 13px;
        }
      }

      .contentText2 {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .containerGestionSocial3 {
      transition: 0.5s;
      padding: 5px;
      position: absolute;
      left: 15%;
      bottom: 25%;
      font-family: "Mulish", sans-serif;

      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 270px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;
      transition: height 0.3s;

      @media screen and (max-width: 1250px) {
        right: 75%;
      }

      @media screen and (max-width: 1070px) {
        width: 200px;
        right: 75%;
      }

      @media screen and (max-width: 930px) {
        right: 75%;
        width: 180px;
      }

      @media screen and (max-width: 870px) {
        right: 75%;
        bottom: 25%;
        width: 180px;
      }

      &.see3 {
        height: auto;
      }

      .title3 {
        margin-bottom: 5px;
        font-weight: bold;

        @media screen and (max-width: 1050px) {
          font-size: 14px;
        }

        @media screen and (max-width: 930px) {
          font-size: 13px;
        }
      }

      .contentText3 {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.contentnone {
  display: none;

  @media screen and (max-width: 740px) {
    .content {
      &__img-Social {
        display: none;
      }
    }

    .contentnone {
      transition: 0.5s;
      display: block;
      font-family: "Mulish", sans-serif;
      flex-direction: column;

      .contentnone__img {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: scale(1.01);
          transition: 0.5s;

          &::before {
            filter: blur(5px);
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(1px);
        }

        .textAmbiental {
          top: -16%;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 20px;
          border-radius: 8px;
          backdrop-filter: blur(0.8px);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          max-width: 500px;
          color: #000;
          overflow: hidden;
          position: relative;

          h2 {
            font-size: 20px;
            color: #535353;

            span {
              font-family: "Dancing Script", cursive;
              font-size: 30px;
              color: #535353;
            }
          }

          h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
            color: rgb(110, 190, 74);
          }

          p {
            font-size: 18px;
            line-height: 0.9;
            text-align: center;
          }
        }

        .prueba {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .containerGestionSocial {
            transition: 0.5s;
            padding: 5px;
            position: absolute;
            left: 34%;
            bottom: 31%;
            font-family: "Mulish", sans-serif;
            background: rgba(255, 255, 255, 0.7);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            width: 250px;
            height: 100px;
            border-radius: 3px;
            overflow: hidden;
            transition: height 0.3s;

            @media screen and (max-width: 470px) {
              bottom: 28%;
            }

            @media screen and (max-width: 407px) {
              left: 30%;
            }

            @media screen and (max-width: 380px) {
              bottom: 25%;
            }

            &.see {
              height: auto;
            }

            .title {
              font-size: 12px;
              font-weight: bold;

              @media screen and (max-width: 930px) {
                font-size: 13px;
              }
            }

            .contentText {
              font-size: 12px;
              line-height: 16px;
            }
          }

          .containerGestionSocial2 {
            transition: 0.5s;
            padding: 5px;
            position: absolute;
            left: 13%;
            bottom: 10%;
            font-family: "Mulish", sans-serif;
            background: rgba(255, 255, 255, 0.7);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            width: 250px;
            height: 100px;
            border-radius: 3px;
            overflow: hidden;
            transition: height 0.3s;

            @media screen and (max-width: 470px) {
              left: 9%;
            }

            @media screen and (max-width: 407px) {
              left: 4%;
            }

            @media screen and (max-width: 380px) {
              left: 2%;
            }

            &.see2 {
              height: auto;
            }

            .title2 {
              font-size: 12px;
              font-weight: bold;

              @media screen and (max-width: 930px) {
                font-size: 13px;
              }
            }

            .contentText2 {
              font-size: 12px;
              line-height: 16px;
            }
          }

          .containerGestionSocial3 {
            transition: 0.5s;
            padding: 5px;
            position: absolute;
            left: 58%;
            bottom: 10%;
            font-family: "Mulish", sans-serif;
            background: rgba(255, 255, 255, 0.7);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            width: 250px;
            height: 100px;
            border-radius: 3px;
            overflow: hidden;
            transition: height 0.3s;

            @media screen and (max-width: 470px) {
              left: 55%;
            }

            @media screen and (max-width: 407px) {
              left: 51%;
            }

            &.see3 {
              height: auto;
            }

            .title3 {
              font-size: 12px;
              font-weight: bold;

              @media screen and (max-width: 930px) {
                font-size: 13px;
              }
            }

            .contentText3 {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

.cardsContent-Soc{
  transition: 0.5s;
  padding: 5px;
  /* position: absolute; */
  right: 10vw;
  top: 30vh;
  /* width: 45%; */
  /* height: 100px; */
  border-radius: 3px;
  overflow: hidden;
  transition: height 0.3s;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 870px) {
    transition: 0.5s;
  padding: 5px;
  right: 10vw;
  top: 30vh;
  border-radius: 3px;
  overflow-x: auto; /* Permitir scroll horizontal */
  white-space: nowrap; /* Evitar el ajuste automático del contenido a varias líneas */
  display: flex;
  flex-wrap: nowrap; /* Evitar que el contenido se divida en varias líneas */
  justify-content: flex-start; /* Alinear el contenido a la izquierda */
  align-items: center;
  gap: 10px;
  }
}