* {
    margin: 0;
    box-sizing: border-box;
  }
  
  .container {
    width: 100%;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  
  .container .div {
    scroll-snap-align: start;
    width: 100%;
    height: 100vh;
    font-size: 5em;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .container .div:nth-of-type(even) {
    background-color: #fff;
    color: white;
    height: 100vh;
  }
  
  /* Scrollbar modification */
  .container::-webkit-scrollbar {
    width: 0px;
  }
  
  .container::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .container::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  .hubspot-link__container sproket{
    display: none !important;
    visibility: hidden !important;
}
  