.mainNews{
    &__title{
        font-family: 'Mulish', sans-serif;
        h2{
            font-size: 3rem;
            color: white;
            font-family: 'Mulish', sans-serif;
            padding-bottom: 30px;
            @media screen and (max-width:375px){
                text-align: center;
            }
        }
        span{
            font-size: 14px;
            color: #777779;
            font-family: 'Mulish', sans-serif;
        }
   
    }
    &__container{
        display: flex;
        flex-direction: column;
        height: 60vh;
        align-items: center;
    justify-content: center;
    position: relative;
    }
    &__cards{
        display: flex;
        flex-direction: row;
        width: 90vw;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 65vh;
        .swiper-wrapper{
            justify-content: center;
            @media screen and (max-width:910px) {
                justify-content: unset;
            }
          }
        @media screen and (max-width:1024px) {
            height: 70vh;
            
        }
    }
}

.background-4{
    background-image: url('https://res.cloudinary.com/dgnwqr93n/image/upload/q_100/v1689218726/_MG_9782_enzzlu.webp');
    // background-color: #6ebe4a;
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}
.background-4::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.436);
}
.texto{
    font-size: 1rem;
    color: #eee;
    @media screen and (max-width:535px) {
        font-size: 0.875rem;
        text-align: center;
    }
}