@keyframes moveDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .btn-know-more {
    cursor: pointer;
  }
  
  .icon__arrow {
    color: white;
    font-size: 2rem;
    margin-top: 1rem;
    animation: moveDown 1.5s ease-in-out infinite;
  }
  