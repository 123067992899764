.btn-action{
    border: 0.5px solid #fff;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border-radius: 10px;
    font-family: 'Mulish', sans-serif;
    color: white;
    font-size: 0.9rem;
    &:hover{
        background-color: #6ebe4a;
        border: 1px solid #6ebe4a;
    }
}

.btn-link{
    height: 40px;
    display: flex;
    align-items: center;
}
.btn{
    cursor: none;
    height: 39px;
    display: flex;
    text-decoration: none;
}
