

.mainNav{
    position: absolute;
    width: 100%;
    z-index: 2;
    backdrop-filter: blur(100px);
    // padding-top: 20px;
    @media screen and (max-width:710px) {
      height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: unset;
    // height: 80px;
    // width: 100vw;
    // -webkit-backdrop-filter: blur(10px);
    // backdrop-filter: blur(10px);
    &.bg-green{
        height: 80px;
        width: 100%;
        background-color: #6fbe4a81;
        @media screen and (max-width:535px){
          position: fixed;// fixed?          
        }
        .bm-menu{
            transform: translateY(-40px);
        }
        
    }
    }
    @media screen and (max-width:910px) {
      width: 97.5%;
    }
    
    // background-color: transparent;
   &__nav{
    
       margin: 0;
       padding: 0;
       overflow: hidden;
       background-color: transparent;
    //    max-width: 98.8vw;
    
       ul{
           display: flex;
           list-style-type: none;
           align-items: center;
           justify-content: space-evenly;
           color: white;
           font-weight: 600;
           font-family: 'Mulish', sans-serif;
           height: 60px;
             @media screen and (max-width:710px) {
                 flex-direction: column;
                 align-items: flex-end !important;
                
             } 
           a{
            text-decoration: none;
            color: white;
            
            display: flex;
            position: relative;
            &:hover{
              opacity: 1;
            }
            &::before {
              transition: 300ms;
              height: 5px;
              content: "";
              position: absolute;
              background-color: #e3e3e3;
              
            }
            
            &.effect::before {
              width: 100%;
              bottom: 15px;
              opacity: 0;
            }
            &.effect:hover::before {
              bottom: -10px;
              opacity: 1;
            }
           
            
           }
      
        }
        img{
            width: 200px;
            // @media screen and (max-width:710px) {
            //     position: absolute;
            //     width: 150px;
            //     left: 0;
            //     top: 0;
            // }
        }
    }
    img{
        width: 150px;
        @media screen and (max-width:710px) {
            position: absolute;
            width: 120px;
            left: 0;
            top: -3.3px;
        }
    }
    figure{
        @media screen and (max-width:765px) {
            position: absolute;
            top: 10px;
            left: 10px;
            
        }
    }
    .menu-container {
      // position: absolute;
      display: inline-block;
    
      .menu-trigger {
        cursor: pointer;
        font-size: 1rem;
        margin-right: 10px;
      }
    
      .menu-dropdown {
        
        position: absolute;
        top: 100%;
        right: 50px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px 12px;
        list-style: none;
        display: none; // Hide the menu by default
        z-index: 2;
        width: 200px;
        height: 100px;
    
        li {
          width: 100%;
          cursor: pointer;
          padding: 8px;
          transition: background-color 0.3s;
          color: #6ebe4a;
    
          &:hover {
            background-color: #ddd;
            border-radius: 5px;
          }
        }
      }
    
      .menu-dropdown {
        display: flex; // Show the menu when hovering over the container
        flex-direction: column;
        align-items: flex-start;
        
      }
    }
}

.bg-green{
    background-color: #6ebe4a;
    @media screen and (max-width:710px) {
        background-color: #6ebe4aa1;
        .bm-burger-bars {
            background: #fff;
          }
    }
}
// .bm-menu-wrap{
//     width: 50%;
// }
.bm-item{
    color: white;
    text-decoration: none;
}

.bm-item-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 10px;
    top: 30px;
  
    @media screen and (max-width:910px) {
      // top:20px;
    }
    @media screen and (max-width:768px) {
      right: 15px;
      top: 28px;
    }
    // @media screen {
      
    // }
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #6ebe4a;

    @media screen and (max-width:910px) {
      background-color: white;
    }

  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: white;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
   width: 60% !important;
    height: 100% !important;
    top: 0px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #6ebe4a;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    font-family: 'Mulish', sans-serif;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  