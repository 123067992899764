// .WhatToDoDescription {
//     &__card {
//         &__container {
//             position: relative;
//             display: flex;
//             flex-direction: row;
//             display: flex;
//             align-items: center;
//             background-color: #fff;
//             padding: 0.5rem 1rem .5rem 5rem;
//             border-radius: 20px;
//             color: gray;
//             height: 90px;
//             // padding-left: 50px;
//             width: 280px;
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             &:hover {
//                 filter: none;
//                 opacity: 1;
//             }

//         }

// &__description {
//         figure {
//             img {
//                 width: 120px;
//                 height: 120px;
//                 border-radius: 50%;
//                 display: flex;
//                 align-items: center;
//                 object-fit: cover;
//                 position: absolute;
//                 top: -17px;
//                 left: -55px;
//                 border: 5px solid #fff;
//             }
//         }
//     }

//         &__description {
//             display: flex;
//             flex-direction: column;

//             //padding: 1rem;
//             p {
//                 font-size: 1rem;
//             }

//             &__name {
//                 display: flex;
//                 flex-direction: row;
//                 gap: .5rem;
//             }

//         }
//     }

//     &__socialMedia {
//         display: flex;
//         flex-direction: column;
//         gap: .5rem;
//         filter: grayscale(100%);
//         opacity: 0.6;
//         transition: all 0.3s ease;
//         padding: .3rem .3rem 0.3rem 1rem;
//         border-left: 1px dotted gray;
//         margin-left: 12px;

//         img {
//             width: 20px;
//             height: 20px;
//         }

//         &:active,
//         &:hover {
//             filter: none; // Restaura el color original de la imagen
//             opacity: 1;
//         }
//     }
// }
.WhatToDoDescription {
    &__card {
        &__container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #fff;
            padding: 0.5rem 1rem 0.5rem 5rem;
            border-radius: 20px;
            color: gray;
            height: 90px;
            width: 280px;
            display: flex;
            flex-direction: row;
            align-items: center;

            &:hover {
                background-color: #6EBE4A;
                color: #fff;
                filter: none;
                opacity: 1;
            }
        }

        &__description {
            figure {
                img {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    object-fit: cover;
                    position: absolute;
                    top: -17px;
                    left: -55px;
                    border: 5px solid #fff;
                }
            }

            display: flex;
            flex-direction: column;

            p {
                font-size: 1rem;
            }

            &__name {
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
            }
        }
    }

    &__socialMedia {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        filter: grayscale(100%);
        opacity: 0.6;
        transition: all 0.3s ease;
        padding: 0.3rem 0.3rem 0.3rem 1rem;
        border-left: 1px dotted gray;
        margin-left: 12px;

        img {
            width: 20px;
            height: 20px;
        }

        &:hover {
            filter: none;
            opacity: 1;
        }
    }

    &__otherName {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        object-fit: cover;
        position: absolute;
        top: -17px;
        left: -55px;
        border: 5px solid #fff;
        justify-content: center;
        font-size: 3rem;
        background-color: rgb(231, 226, 226);
        color:#6EBE4A;
        font-weight: bold;
    }
}