.testimonials {
    font-family: 'Mulish', sans-serif;
    &__background {
        background-image: url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261449/ayapel/auetojlk1la9iugyfetc.jpg');
        // background-color: #6ebe4a;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        z-index: 3;
        flex-wrap: wrap;
    }
}



.testimonialsCard {
    position: relative;
    height: 60vh;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
    align-items: center;

    &__title {
        font-size: 16px;
        font-weight: 700;
        color: #535353;
        font-family: 'Mulish', sans-serif;
        margin-top: 3.5rem;
    }

    &__text {
        font-size: 12px;
        font-family: 'Mulish', sans-serif;
        height: 125px;
        overflow: auto;
        margin-top: .6rem;
        text-indent: 30px;
        text-align: justify;
      
    }

    &__img {

        z-index: 2;

        figure {
            img {
                border-radius: 100%;
                width: 150px;
                height: 150px;
                object-fit: cover;
                // border: 2.5px solid #6ebe4a;

            }
        }
    }

    &__content {
        background-color: white;
        height: 200px;
        transform: translateY(-80px);
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        width: 270px;
        padding: 5px 20px 10px 20px;
        gap: 5px;
        justify-content: flex-end;
        // border: 9.5px solid rgba(193, 196, 191, 0.5);

    }

    &__gestion {
        h2 {
            color: white;
            font-family: 'Mulish', sans-serif;
        }
    }
}

.bg__div {
    border-radius: 100%;
    // background-color: #6ebe4a;
    background-color: rgba(193, 196, 191, 0.5);
    width: 170px;
    height: 170px;
    //object-fit: cover;
    // border: 3.5px solid #6ebe4a;
    position: absolute;
    top: 8px;
    left: 70px;
    backdrop-filter: blur(10px);
}

.bg__divContent {
    border-radius: 10%;
    border-radius: 10%;
    background-color: rgba(193, 196, 191, 0.5);
    width: 291px;
    height: 225px;
    object-fit: cover;
    position: absolute;
    top: 100px;
    left: 9px;
    //-webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}


.icon__quote,
.icon__quote__right {
    display: flex;
    align-items: flex-start;
    text-align: start;
    color: #777779;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 85px;
    left: 12px;

}

.icon__quote__right {
    top: 160px;
    left: 210px;
}

.testimonials {
    &__container {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        font-size: 4rem;
        color: #535353;
       align-items: center;
       justify-content: center;
       margin-top: 1rem;
        &__rowLeft {
            font-size: 2rem;
            color: #535353;
            
        }
        &__carousel {
            width: 920px;
            overflow-x: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction:row;
            transition: scroll 2.9s ease;
        }
    }
    &__ctaDonations {
        display: flex;
        justify-content: center;
    }
    &__next {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
    &__title {

        color: #FFF;
        text-align: center;
        font-size: 2rem;
       // margin-bottom: 3rem;
        margin-top: 5rem;
    }
}


// ********************MEDIA QUERY**************************
@media (max-width: 890px) {
    .testimonials {
        &__container {
            display: flex;
            flex-direction: row;
            gap: 0;
            font-size: 2rem;
            color: #535353;
           align-items: center;
           width: 100vw;
            &__rowLeft {
                font-size: 2rem;
                color: #535353;
                
            }
            &__carousel {
                width: 318px;
            
            }
        }
    }
    .testimonials__container__rowLeft,
    .testimonials__container__rowRight {
        @media (max-width: 375px) {
            display: inline-block;
        }
    }
}

@media (min-width: 891px) and (max-width: 1150px) {
    .testimonials {
        &__container {
      
            &__rowLeft {
                font-size: 2rem;
                color: #535353;
                
            }
            &__carousel {
                width: 624px;
             
            }
        }
    }
}