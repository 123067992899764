.btn__quantity {
    height: 45px;
    width: 100%;
    border: none;
    cursor: pointer;
    z-index: 6;
    background-color: #FFF;
    color: #303030;
    border-radius: 80px;
    outline: none;
    margin: 0;
    padding: 0 2rem 0 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 
    0px 1px 0px #6EBE4A,
    0px 2px 0px #6EBE4A,
    0px 3px 0px #6EBE4A,
    0px 4px 0px #6EBE4A;

  

    .gif-container {
        display: inline-block;
        width: 40px;
        height: 40px;
        background-image: url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1688167470/ayapel/xhwaslf4c5sm8r6snozb.gif');
        background-size: cover;
        background-position: center;
    }

    &__value {
        width: 80px;
        height: 80px;
    }
}

.btn__quantity.active, .btn__quantity:hover {
    background-color: #6EBE4A;
    color: #fff;
    box-shadow: 0px 1px 0px hsl(0, 0%, 46%),
        0px 2px 0px hsl(0, 0%, 48%),
        0px 3px 0px hsl(0, 0%, 49%),
        0px 4px 0px hsl(0, 0%, 50%),
        0px 5px 10px #777779;

}


// ********************MEDIA QUERY**************************

@media (max-width: 720px) {
    .btn__quantity {
        height: 35px;
        width: 100%;
        font-size: .9rem;
        border-radius: 80px;
        padding: 0 2rem 0 2rem;
        .gif-container {
            width: 50px;
            height: 50px;
            display: none;

        }

        &__value {
            width: 80px;
            height: 80px;
        }
    }

    .btn__quantity.active {
        background-color: #6EBE4A;
    }
   

}

@media (min-width: 721px) and (max-width: 991px) {
    .btn__quantity {
        height: 100%;
        width: 100%;
        font-size: .9rem;
        border-radius: 80px;
        padding: 0 2rem 0 2rem;

        .gif-container {
            width: 50px;
            height: 50px;
        }

        &__value {
            width: 80px;
            height: 80px;
        }
    }

    .btn__quantity.active {
        background-color: #6EBE4A;
    }
}


@media (min-width: 992px) {}