// .mainDocuments{
//     display: flex;
//     width: 90vw;
//     height: 100vh;
// }
.mainDocuments{
    background-image: url('https://res.cloudinary.com/dgnwqr93n/image/upload/v1688679819/Untitled_design_2_vt8pcd.png');
    // background-color: #6ebe4a;
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    &__main{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 80vw;
        height: 50vh;
        @media screen and (max-width:375px){
            font-size: 0.875rem;
            height: 75vh;
        }
        @media screen and (max-width:475px){
            font-size: 0.975rem;
            height: 75vh;
        }
    }
    &__title{
        h2{
            color: #6ebe4a;
            font-size: 3.5rem;
            @media screen and (max-width:375px){
                font-size: 2.5rem;
              
            }
            @media screen and (max-width:475px){
                font-size: 2.5rem;
                
            }
        }

        select{
            border: 0px;
            padding: 10px 20px;
            background-color: #6ebe4a;
            color: white;
            border-radius: 5px;

        }
    }


    &__document-container{
        padding-bottom: 10px;
        a{
            text-decoration: none;
            color: white;
            &:hover{
                color: #6ebe4a;
            }
        }
        svg{
            color: #6ebe4a;
        }
    }
    &__documents{
        padding-top: 10px;
    }
}
.mainDocuments::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.396);
}

