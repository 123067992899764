.whatToDo {
    &__container {
        display: flex;
        color: #fff;
        width: 100%;
        padding: 0 15% 0 15%;
    }

    &__list {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px 0 60px;
        gap: 2rem;
        margin-bottom: 2.5rem;
        background-color: gray;
        border-radius: 0px 40px 40px 20px;
        position: relative;
        height: 40px;

        &.active,
        &:hover {
            background-color: #6EBE4A;
        }

        li {
            list-style: none;
        }

        figure {
            padding: 5px 5px;
            position: absolute;
            top: -17px;
            left: -20px;

            img {
                width: 60px;
                height: 60px;
                filter: grayscale(100%);
                opacity: 0.6;
                transition: all 0.3s ease; // Agrega una transición suave al cambio de estilo
            }
        }

        &.active,
        &:hover {

            // Estilos cuando el mouse está sobre el elemento
            img {
                filter: none; // Restaura el color original de la imagen
                opacity: 1; // Restaura la opacidad original de la imagen
            }
        }
    }
}

