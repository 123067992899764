.other {
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__one {
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // gap: -70px;

    .title,
    .contentImg {
      padding: 20px;
      width: 100%;

      @media (min-width: 700px) {
        width: 50%;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      height: 440px;
      text-align: left;

      position: relative;
      top: 15%;
      left: 10px;
      @media (max-width: 740px) {
        border-radius: 5px;
        left: 0;
        height: 35%;
        top: 10%;
      }
      @media (max-width: 700px) {
        top: 90px;
        height: 300px;
      }
      @media (max-width: 380px) {
        height: 340px;
      }
      h2 {
        font-size: 33px;
        // line-height: 123px;
        line-height: 2.5rem;
        color: #535353;
        font-weight: 400;
        @media (max-width: 810px) {
          font-size: 30px;
        }
        @media (max-width: 745px) {
          font-size: 28px;
        }
        @media (max-width: 710px) {
          font-size: 25px;
        }
        span {
          font-family: "Dancing Script", cursive;
          font-weight: 600;
          font-size: 66px;
          color: rgb(110, 190, 74);
          line-height: 49px;
          @media (max-width: 815px) {
            font-size: 60px;
          }
          @media (max-width: 745px) {
            font-size: 57px;
          }
          @media (max-width: 710px) {
            font-size: 55px;
          }
        }
      }

      p {
        font-size: 16px;
        color: #777779;
        margin: 0 0 15px;
      }
    }
    .contentImg {
      margin-top: -80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.5s;

      // @media (max-width: 700px) {
      // margin-top: -120px;
      // }

      img {
        max-width: 100%;
        max-height: 80%;
        @media (max-width: 740px) {
          position: relative;
          top: 30%;
        }
        @media (max-width: 700px) {
          top: 33%;
        }
        @media (max-width: 670px) {
          // top: 20%;
        }
        @media (max-width: 480px) {
          // top: 20%;
        }
        @media (max-width: 400px) {
          top: 38%;
        }
        // @media (max-width: 400px) {
        //   margin-bottom: -50px;
        //   }
      }
    }

    @media (max-width: 740px) {
      flex-direction: column;

      .title,
      .contentImg {
        width: 100%;
      }
    }
  }
}
