@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
// font-family: 'Dancing Script', cursive;

.content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__img-Economic {
    position: relative;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    // z-index: 0.5;
    // flex-wrap: wrap;

    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    font-family: "Mulish", sans-serif;
    @media screen and (max-width: 870px) {
      width: 100vw;
      // height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .content-Economic {
      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      max-width: 500px;
      top: 15%;
      color: #000;
      font-size: 24px;
      text-align: right;
      word-wrap: break-word;
      overflow: hidden;
      @media screen and (max-width: 870px) {
        top: 15%;
      }
      @media screen and (max-width: 870px) {
      text-align: center;
      }
      h2 {
        font-weight: 600;
        // margin-bottom: 5px;
        font-size: 36px;
        color: #535353;
        line-height: 49px;
        margin-top: 0;
        @media screen and (max-width: 870px) {
        font-size: 33px;
        line-height: 0;
        }
        @media screen and (max-width: 775px) {
        font-size: 30px;
        }
        span {
          font-family: "Dancing Script", cursive;
          font-size: 103px;
          line-height: 123px;
          color: #535353;
          font-weight: 400;
          @media screen and (max-width: 870px) {
          font-size: 90px;
          }
          @media screen and (max-width: 775px) {
          font-size: 80px;
          }
        }
      }
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
        color: rgb(110, 190, 74);
      }

      p {
        font-size: 18px;
        line-height: 1.2;
        @media screen and (max-width: 870px) {
          font-size: 16px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 20px;
          }
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #535353;
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
          }
          height: 150px;
        }
      }
    }

    .containerProject {
      transition: 0.5s;
      padding: 5px;

      // position: absolute;
      right: 75%;
      top: 45%;

      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 300px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;
      transition: height 0.3s;
      @media screen and (max-width: 1200px) {
        width: 250px;
        right: 78%;
      }
      @media screen and (max-width: 1070px) {
        width: 200px;
        right: 78%;
      }
      @media screen and (max-width: 930px) {
        right: 78%;
        width: 190px;
        height: 80px;
        text-align: unset;
      }
      @media screen and (max-width: 830px) {
        right: 75%;
      }
      @media screen and (max-width:376px){
        width: 150px;
      }
      &.see {
        height: auto;
        @media screen and (max-width: 930px) {
          height: 80px;
          height: 280px;
          position: absolute;
          top: 25%;
          left: 2.5%;
          width: 95vw;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          font-size: 18px;
        }
      }
      .title {
        margin-bottom: 5px;
        font-weight: bold;
        color: #000000;
        font-family: "Mulish", sans-serif;
        @media screen and (max-width: 930px) {
          font-size: 13px;
        }
      }

      .contentText {
        font-size: 12px;
        line-height: 16px;
        color: #000;
      }
    }

    .containerProject2 {
      padding: 5px;

      position: absolute;
      left: 30%;
      top: 45%;

      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 300px;
      // height: 110px;
      border-radius: 3px;
      overflow: hidden;
      transition: height 0.3s;
      @media screen and (max-width: 1220px) {
        width: 250px;
        right: 55%;
      }
      @media screen and (max-width: 1200px) {
        right: 50%;
      }
      @media screen and (max-width: 1070px) {
        width: 200px;
        right: 53%;
      }
      @media screen and (max-width: 930px) {
        right: 56%;
        width: 180px;
      }
      @media screen and (max-width: 830px) {
        right: 52%;
      }
      @media screen and (max-width: 7680px) {
        left: 26%;
      }
      &.see2 {
        height: auto;
      }

      .title2 {
        margin-bottom: 5px;
        font-weight: bold;
        @media screen and (max-width: 930px) {
          font-size: 13px;
        }
      }

      .contentText2 {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

.contentnone {
  display: none;
}

@media screen and (max-width: 740px) {
  .content {
    &__img-Economic {
      // display: none;
    }
  }
  .contentnone {
    transition: 0.5s;
    display: block;
    font-family: "Mulish", sans-serif;
    flex-direction: column;

    .contentnone__img {
      width: 100%;
      height: 100vh;
      background-size: cover;
      background-position: center;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        transform: scale(1.01);
        transition: 0.5s;
        &::before {
          filter: blur(5px);
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(1px);
      }

      .textAmbiental {
        top: -16%;
        background-color: rgba(255, 255, 255, 0.7);
        padding: 20px;
        border-radius: 8px;
        backdrop-filter: blur(0.8px);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        // height: 50%;
        max-width: 500px;
        color: #000;
        overflow: hidden;
        position: relative;
        h2 {
          font-size: 20px;
          color: #535353;
          span {
            font-family: "Dancing Script", cursive;
            font-size: 30px;
            color: #535353;
          }
        }
        h3 {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 5px;
          color: rgb(110, 190, 74);
        }

        p {
          font-size: 18px;
          line-height: 0.9;
          text-align: center;
        }
      }
      .containerProject {
        transition: 0.5s;
        padding: 5px;

        position: absolute;
        left: 15%;
        top: 65%;

        background: rgba(255, 255, 255, 0.7);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 300px;
        height: 100px;
        border-radius: 3px;
        overflow: hidden;
        transition: height 0.3s;
        @media screen and (max-width: 465px) {
          left: 7%;
        }
        @media screen and (max-width: 398px) {
          left: 5%;
        }
        @media screen and (max-width: 380px) {
          left: 2%;
        }
        @media screen and (max-width: 830px) {
          right: 65%;
          width: 180px;
        }
        &.see {
          height: auto;
        }
        .title {
          margin-bottom: 5px;
          font-weight: bold;
        }

        .contentText {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .containerProject2 {
        padding: 5px;

        position: absolute;
        left: 55%;
        top: 65%;

        background: rgba(255, 255, 255, 0.7);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        width: 300px;
        height: 110px;
        border-radius: 3px;
        overflow: hidden;
        transition: height 0.3s;
        @media screen and (max-width: 398px) {
          left: 53%;
        }
        @media screen and (max-width: 830px) {
          right: 65%;
          width: 180px;
        }
        &.see2 {
          height: auto;
        }

        .title2 {
          margin-bottom: 5px;
          font-weight: bold;
        }

        .contentText2 {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.cardsContent{
  transition: 0.5s;
  padding: 5px;
  /* position: absolute; */
  right: 10vw;
  top: 30vh;
  /* width: 45%; */
  /* height: 100px; */
  border-radius: 3px;
  overflow: hidden;
  transition: height 0.3s;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  // @media screen and(max-width:870px){
  //   flex-direction: co;
  // }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: white;
  padding: 20px;
  flex-direction: column;

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
  }

  button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    background-color: #6ebe4a;
    padding: 5px 10px;
    margin-top:10px;
    border-radius: 4px;
  }
}