.background-2{
    // background-image: url('https://res.cloudinary.com/dgnwqr93n/image/upload/q_100/v1689218712/DSC04109_eg6sga.webp');
    // background-color: #6ebe4a;
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}
.background-2::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.436);
}

.mainWeAre{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    justify-content: flex-start;
    align-items: center;
    width: 80vw;
    // background-image:url('https://res.cloudinary.com/dgnwqr93n/image/upload/v1688177236/paisa-1_sxyk42.jpg') ;
    // background-repeat: no-repeat;
    // background-size: cover;
    @media screen and (max-width:1068px){

        gap: 20px;
        height: 75vh;
    }
    @media screen and (max-width:450px) {
        height: 60vh;
    }
    @media screen and (max-width:910px){
justify-content: center;
    }
    &__title{
        h2{
            font-size: 3rem;
            color: white;
            font-family: 'Mulish', sans-serif;
            
            @media screen and (max-width:535px) {
                font-size: 2rem;
            }
        }
    }
    &__text{
        p{
            font-size: 1rem;
            color: white;
            font-family: 'Mulish', sans-serif;
            text-align: center;
           display: flex;
            @media screen and (max-width:535px) {
                font-size: 0.875rem;
                text-align: center;
            }
        }
    }
    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50vw;
        gap: 20px;
        position: relative;
        @media screen and (max-width:535px) {
         width: 75vw;   
        }
    }
    &__buttons{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 20px;
        font-family: 'Mulish', sans-serif;
        // button{

        //     &:hover{
        //         background-color: white;
        //         color: #6ebe4a;
        //         border:1px solid #6ebe4a;

        //     }
        // }
 
        
    }
    &__button{
        display: flex;
        position: absolute;
    align-items: center;
    justify-content: center;
    bottom: 50px;
    right: 45px;
    @media screen and (max-width:1068px){
        // position: unset;
        bottom: 50px;
        right: unset;
    }
    @media  screen and (max-width:535px) {
        position: unset;
    }
    }
    &__btn-donacion{
        position: absolute;
    right: 0;

    }
}

.btn{
    cursor: none;
    height: 39px;
    display: flex;
    text-decoration: none;
}

