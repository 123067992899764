.navPageAyapel {
  &__container {
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    //margin-top: 3%;
    background-color: #fff;
    width: auto;
    border: 2px solid grey;
    height: 100%;
    border-radius: 1rem 0 0 1rem;
    display: flex;
    justify-content: space-around;
    //padding: 0.5rem 1rem .5rem 1rem;
    flex-direction: row;
    color: gray;
    font-size: 100%;
    padding: auto;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;


    ul {
      display: flex;
      gap: 2rem;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        justify-content: space-around;
        background-color: transparent;
        font-size: 100%;

        &.active {
          background-color: #6EBE4A;
          width: auto;
          padding: 0 1rem 0 1rem;
          border-radius: 10px;
          color: #fff;
          transform: translateX(5px) scale(1.15);
        //  margin: 0 20px;
        }
      }
    }
  }
}
