.mainNew {
    background-color: #f5f5f5;
    padding: 10rem 10rem 1rem 10rem;
    @media screen and (max-width:910px) {
      padding: 2rem 6.5rem 1rem  6.5rem;
    }
    @media screen and (max-width:535px) {
      padding: 2rem 0.5rem 1rem  0.5rem;
    }



    &__social-share{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      z-index: 2;
      @media screen and (max-width:535px) {
        z-index: 1;
      }

      @media screen and (max-width:910px) {
        z-index: 1;
      }

    }
    &__social-icons{
      display: flex;
      gap: 5px;
    }

    &__head {
      padding: 2rem;
      background-color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-width: fit-content;
      border-radius: 5px 5px 0px 0px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

    &__title {
      font-size: 3rem;
      margin-bottom: 4px;
      @media screen and (max-width:535px) {
        font-size: 2rem;
      }
    }

    &__image {
      padding-bottom: 10px;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &__body {
      padding: 10px 35px;
      background-color: white;
      min-height: fit-content;
      flex-direction: column;
      align-items: center;
      display: flex;
      max-height: fit-content;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    }

    div {
      width: 100%;

      figure {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          width: 50%;
          margin: 5px;
          border-radius: 4px;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        text-align: left;
        margin: 0 0 15px;
        margin-bottom: 20px;
        z-index: 2;
        @media screen and (max-width:535px) {
          z-index: 1;
          text-align: justify;
        }

        @media screen and (max-width:910px) {
          z-index: 1;
        }

        a {
             /* background-color: rgba(86, 188, 39, 0.4784313725); */
    color: #418d1d;
    text-decoration: none;
    /* padding: 3.3px 10px; */
    /* border-radius: 5px; */
    margin: 0px 2px;
    font-weight: 700;
    border-bottom: 1px solid #418d1d;
        }
      }
    }

    &__date {
      background-color: #6ebf4968;
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: #41722b;
      @media screen and (max-width:535px) {
        width: 30vw;
      }

      @media screen and (max-width:910px) {
        width: 30%;
      }
    }
    &__fondo-1 {
      position: fixed;
      top: 0;
      right: 0;
      // left: 0;
      bottom: auto;
      // transform: translateY(-100%);
      @media screen and (max-width:535px) {
        display: none;
      }
    }

    &__fondo-2 {
      position: fixed;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      @media screen and (max-width:535px) {
        display: none;
      }
      // transform: translateY(100%);
    }

  }
