$pColor: rgb(217, 217, 217);

.toHelp{
    width: 100%;
    display: flex;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow-y: scroll;
    &__Banner{
        width: 100%;
        height: 100vh;
        background-image: url(https://res.cloudinary.com/dgnwqr93n/image/upload/v1688153550/slide-1_fgfpri.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        flex-wrap: wrap;
    }

    &__Content{
        margin: 150px 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }

    &__Banner::before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.436);
    }

    &__p{
        min-width: 250px;
        max-width: 800px;
        text-align: center;
        font-size: 20px;
        margin-bottom: 40px;
        color: $pColor;
        font-family: "Mulish", sans-serif;
    }

}

.miniCarrousel{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &__Container{
        width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        margin: 0 35px;
    }

    &__Image__Container{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

    }

    &__Buttons__Container{
        position: absolute;
        height: 30px;
        bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Button{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: white;
        margin: 0 5px;
        border: none;
        cursor: pointer;
        transform: scale(0.7);
        &.active{
            border: 1px solid white;
            background-color: transparent;
            transform: scale(1);
        }
    }
    
    &__Image{
        width: 100%;
        height: 225px;
        border-radius: 12px;
        margin-bottom: 15px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .Program__button{
        height: 45px;
        border-radius: 6px;
        background-color: var(--primaryColor);
        color: white;
        border: none;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Mulish", sans-serif;
    }

}

.formulario{
    width: 100%;
    
    &__voluntarios{
        width: 100%;
        height: 100vh;
        // background-image: url(https://res.cloudinary.com/dgnwqr93n/image/upload/q_100/v1689218712/DSC04109_eg6sga.webp);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;
    }
    
    &__voluntarios::before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.436);
    }
    
    &__empresas{
        width: 100%;
        height: 100vh;
        // background-image: url(https://res.cloudinary.com/dgnwqr93n/image/upload/v1688177236/paisa-1_sxyk42.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;
    }

    &__empresas::before{
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.436);
    }

    
}

.toHelp .sec{
    scroll-snap-align: start !important;
    height: 100vh;
}

.toHelp::-webkit-scrollbar {
    width: 0;
}

@media screen and (max-width: 940px) {
    .toHelp__Content{
        margin: 98px 0;
    }

    .sec{
        height: auto !important;
    }
}