@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

$primary-color: #535353;
$secondary-color: rgb(110, 190, 74);

// Define un mixin para las propiedades comunes de los contenedores
@mixin container-styles($left, $bottom) {
  color: black;
  transition: 0.5s;
  padding: 5px;
  left: 48%;
  bottom: 60%;
  font-family: "Mulish", sans-serif;
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 195px;
  // height: 100px;
  border-radius: 3px;
  overflow: hidden;
  transition: height 0.3s;
  @media screen and (max-width:870px) {
    height: 80px;
  }

  &.see {
    height: auto;
    @media screen and (max-width: 930px) {
    height: 80px;
    height: 280px;
    position: absolute;
    top: 25%;
    left: 2.5%;
    width: 95vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
  }

    @media screen and (max-width: 930px) {
      .title{

      font-size: 19px;
      }
      .contentText{
      font-size: 16px;
      padding: 10px;
      font-weight: 400;
      line-height: 130%;
      }

    }
    // @media screen and (max-width: 775px) {
    //   height: 80px;
    // }
  }

  .title {
    margin-bottom: 5px;
    font-weight: bold;
    @media screen and (max-width: 1050px) {
      font-size: 14px;
    }
    @media screen and (max-width: 930px) {
      font-size: 13px;
    }
  }

  .contentText {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .listContent {
    font-size: 12px;
    list-style-type: none;
    padding-left: 1em;
    .listItem {
      position: relative;
      margin-bottom: 0.5em;
      .listDot {
        position: absolute;
        top: 0.4em;
        left: -1em;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #6ebe4a;
      }
    }
  }
}

.content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: 0.5s;

  &__img-Ambiental {
    position: relative;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items:center;
    font-family: "Mulish", sans-serif;
    justify-content: space-evenly;
    @media screen and (max-width:870px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    @media screen and (max-width: 870px) {
      padding: 3rem 20px 0rem 20px;
    }

    .content-Ambiental {
      background: rgba(255, 255, 255, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      max-width: 500px;
      // position: relative;
      top: 20%;
      /* width: 50%; */
      color: #000;
      font-size: 24px;
      text-align: left;
      word-wrap: break-word;
      overflow: hidden;
      @media screen and (max-width: 870px) {
        text-align: center;
      }

      h2 {
        font-weight: 600;
        font-size: 36px;
        color: $primary-color;
        line-height: 49px;
        margin-top: 0;
        @media screen and (max-width: 870px) {
          font-size: 33px;
        }
        @media screen and (max-width: 775px) {
          font-size: 30px;
          line-height: 0;
        }
        @media screen and (max-width: 378px) {
          line-height: 5px;
        }

        span {
          font-family: "Dancing Script", cursive;
          font-size: 103px;
          line-height: 123px;
          color: $primary-color;
          font-weight: 400;
          @media screen and (max-width: 870px) {
            font-size: 90px;
          }
          @media screen and (max-width: 775px) {
            font-size: 75px;
          }
          @media screen and (max-width: 378px) {
            font-size: 70px;
          }
        }
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
        color: $secondary-color;
      }

      p {
        font-size: 18px;
        margin: 0 0 10px;
        line-height: 1.2;
        @media screen and (max-width: 775px) {
          font-size: 16px;
        }
        @media screen and (max-width: 378px) {
        font-size: 16px;
        }

      }
    }

    .containerGestionAmbiental {
      @media screen and (max-width: 775px) {
        width: 190px;
        height: 80px;
      }
      @media screen and (max-width: 378px) {
          height: 80px;
          width: 155px;
        }
      @include container-styles(48%, 60%);
      // width: 180px
      
    }

    .containerGestionAmbiental2 {
      @include container-styles(48%, 40%);
      .listContent {
        .listItem {
          .listDot {
            background-color: $secondary-color;
          }
        }
      }
    }

    .containerGestionAmbiental3 {
      @include container-styles(70%, 60%);
    }

    .containerGestionAmbiental4 {
      @include container-styles(70%, 40%);
      .listContent {
        .listItem {
          .listDot {
            background-color: $secondary-color;
          }
        }
      }
    }
  }
  @media screen and (max-width:870px) {
    flex-direction: column;
  }
}

.contentnone {
  display: none;

  @media screen and (max-width: 740px) {
    .content {
      &__img-Ambiental {
        display: none;
      }
    }

    .contentnone {
      width: 100%;
      height: 100vh;
      transition: 0.5s;
      display: block;
      font-family: "Mulish", sans-serif;
      flex-direction: column;

      .contentnone__img {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          transform: scale(1.01);
          transition: 0.5s;
          &::before {
            filter: blur(5px);
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          background-color: rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(1px);
        }

        .textAmbiental {
          background-color: rgba(255, 255, 255, 0.7);
          padding: 20px;
          border-radius: 8px;
          backdrop-filter: blur(0.8px);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          max-width: 500px;
          color: #000;
          overflow: hidden;
          position: relative;

          h2 {
            font-size: 20px;
            color: $primary-color;

            span {
              font-family: "Dancing Script", cursive;
              font-size: 30px;
              color: $primary-color;
            }
          }

          h3 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
            color: $secondary-color;
          }

          p {
            font-size: 18px;
            line-height: 0.9;
            text-align: center;
          }
        }

        .containerGestionAmbiental {
          @include container-styles(-30%, 30%);
        }

        .containerGestionAmbiental2 {
          @include container-styles(-30%, 10%);
          .listContent {
            .listItem {
              .listDot {
                background-color: $secondary-color;
              }
            }
          }
        }

        .containerGestionAmbiental3 {
          @include container-styles(-20%, 30%);
        }

        .containerGestionAmbiental4 {
          @include container-styles(-20%, 10%);
          .listContent {
            .listItem {
              .listDot {
                background-color: $secondary-color;
              }
            }
          }
        }
      }
    }
  }
}

.cardsContent-Env{
  transition: 0.5s;
  padding: 5px;
  /* position: absolute; */
  right: 10vw;
  top: 30vh;
  /* width: 45%; */
  /* height: 100px; */
  border-radius: 3px;
  overflow: hidden;
  transition: height 0.3s;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 870px) {
    transition: 0.5s;
  padding: 5px;
  right: 10vw;
  top: 30vh;
  border-radius: 3px;
  overflow-x: auto; /* Permitir scroll horizontal */
  white-space: nowrap; /* Evitar el ajuste automático del contenido a varias líneas */
  display: flex;
  flex-wrap: nowrap; /* Evitar que el contenido se divida en varias líneas */
  justify-content: flex-start; /* Alinear el contenido a la izquierda */
  align-items: center;
  gap: 10px;
  }
  @media screen and (max-width: 378px) {
  // background-color: red;
  }
}

.pepe.swiper{
  height: 20%;
}

.pepe.swiper swiper-slide{
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: white;
  padding: 20px;
  flex-direction: column;

  h2 {
    font-size: 24px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    width: 50%;
    @media screen and (max-width:870px){
      width: 100%;
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    background-color: #6ebe4a;
    padding: 5px 10px;
    margin-top:10px;
    border-radius: 4px;
  }
}