.donations {
    font-family: 'Mulish', sans-serif;

    &__background {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261454/ayapel/hcsttfi6ge54qntaiqc5.jpg');
        //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1688247025/ayapel/hyomekkahqrukj8ylpj3.jpg');
        //background-color: #6ebe4a;
        background-size: cover;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-size: cover;
        z-index: 3;
        flex-wrap: wrap;

        h3 {
            font-size: 1.6rem;
        }
    }

    &__main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100vw;
        // margin-top: 2rem;
    }

    &__sectionTypes {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 50%;
        padding: 0 3rem;
        color: #fff;

        h3 {
            font-size: 1.8rem;
            text-align: center;
        }

        p {
            margin: 2rem 0;
            font-size: 1rem;
            text-align: center;
        }

        &__container {
            justify-content: center;

        }
    }

    &__cancelDonation {
        font-size: 1rem;
        color: gray;
        text-align: center;

        span {
            color: #6EBE4A;
        }
    }

    &__howToDonate {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }

    &__formDonationsAmount {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 7vh;
    }


    &__typeDonation {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
    }

    &__sectionDonate {
        width: 35vw;
        z-index: 1;
    }

    &__contactForm {
        font-size: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        //  padding: 1rem;
        min-width: 320px;
        max-width: 517px;
        height: 500px;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

        &__bg {
            width: 100%;
            padding: 5%;

            p,
            h3 {
                font-size: .8rem;
                text-align: center;
                //margin-bottom: 1rem;
                color: #6EBE4A;

            }
        }

    }

    &__btnTypeSelected {
        padding: 1rem;
        background-color: #fff;
        border-radius: 1rem;
        color: #6EBE4A;
        font-weight: bold;
        font-size: 1.5rem;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        //  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }

    &__btnTypeSelected-active,
    &__btnTypeSelected:hover {
        padding: 1rem;
        background-color: #6EBE4A;
        border-radius: 1rem;
        color: #fff;
        font-weight: bold;
        font-size: 1.5rem;
        border: none;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        // box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }

    &__btnDontationFixed {
        margin-bottom: 1rem;
    }


    &__container {
        position: relative;
        background-image: url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689270169/ayapel/es5ocqkufhslegkmmfbm.png');
        background-size: cover;
        object-fit: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        min-width: 320px;
        max-width: 430px;
        height: 500px;
        //background-color: aliceblue;
        flex-wrap: wrap;
        // z-index: 999999;
        top: 0;
        left: 0;
        //  background-color: red;
        //background-image: url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261448/ayapel/oczq1l3syf3bbj8ja09e.jpg');
        // border: 5px solid grey;
        //padding: 2rem;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

        &__ctaBtns {
            display: flex;
            justify-content: center;
            margin-top: .5rem;
            gap: 3rem;
        }

    }

    &__containerAmounts {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;

        &__btns {
            display: flex;
            flex-direction: row;
        }
    }

    &__backCarousel {
        position: absolute;
        min-width: 370px;
        max-width: 517px;
        height: 800px;
    }


    &__videoBackground {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
        width: 100%;
        border-radius: 1rem 1rem 0 30%;
        background-color: #6EBE4A;
       // background-image: url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689261448/ayapel/oczq1l3syf3bbj8ja09e.jpg');
    }

    &__videoBackground-inactive {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        background-color: #fff;
    }


    &__btns {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

    }

    &__image {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        margin-right: 2rem;
        width: auto;

        &__ppl {
            width: auto;
            border-radius: 1rem;
        }
    }

    &__btnConfirmAmount {
        background-color: #6EBE4A;
        color: #fff;
        border: none;
        border-radius: 0 8px 8px 0;
        padding: 0 5px;
    }

    &__columnOne,
    &__columnThree {
        width: 50%;
    }

    &__columnTwo {
        font-family: 'Mulish', sans-serif;
        font-size: 3rem;
        text-align: center;
        position: absolute;
        top: 2px;
        width: 30%;
        color: #6EBE4A;

        p {
            width: 100%;
        }

        &-selected {
            // padding: 1rem;
            margin-bottom: 1rem;
            position: absolute;
            top: 20px;
            color: #6EBE4A;
        }
    }

    &__title {
        font-family: 'Mulish', sans-serif;
        font-size: 1rem;
        margin-bottom: .5rem;
        width: 60%;
        text-align: center;
    }

    &__text {
        font-size: .8rem;
        width: 60%;
        text-align: center;
    }
}

.actions {
    margin-top: 0px !important;
    padding: 0px !important;
    margin-bottom: 0px !important;
}


// ********************MEDIA QUERY**************************
@media (max-width: 375px) {
    .donations {
        &__container {
            margin: 2% 5%;
            height: 310px;
            width: 220px;
        }

        &__main {
            flex-direction: column;
        }

        &__typeDonation {
            flex-direction: column;
        }

        &__sectionTypes {
            width: 100%;
            padding: 0 .5px;

            h3,
            p {
                font-size: 10%;
            }

            p {
                margin: 0;
            }
        }

        &__sectionDonate {
            width: 100%;

        }

        &__typeDonation {
            flex-direction: row;
        }

        &__btnTypeSelected-active,
        &__btnTypeSelected:hover,
        &__btnTypeSelected {
            font-size: 15%;
            padding: .3rem;
        }

        &__background {
            background-size: cover;
        }

        &__title {
            font-size: 100%;

        }
    }
}

@media (max-width: 664px) {
    .hs_email .hs-email .hs-fieldtype-text .field .hs-form-field {
        margin-bottom: 0 !important;
    }
    .field {
        margin-bottom: 0 !important;
    }
    .hubspot-link__container .sproket {
        display: none !important;
    }
    .donations {
        &__container {
            margin: 2% 5%;
            height: 430px;
        }

        &__main {
            flex-direction: column;
        }

        &__typeDonation {
            flex-direction: column;
        }

        &__sectionTypes {
            width: 100%;
            padding: 0 1rem;
            h3 {
                margin-top: 3rem;
            }

            h3,
            p {
                font-size: 100%;
             
            }

            p {
                margin: 0;
                margin-bottom: 1rem;
            }
        }

        &__sectionDonate {
            width: 100%;
        }

        &__typeDonation {
            flex-direction: row;
        }

        &__btnTypeSelected-active,
        &__btnTypeSelected:hover,
        &__btnTypeSelected {
            font-size: 100%;
            padding: .3rem;
            height: 100%;
        }

        &__background {
            background-size: cover;
        }

        &__title {
            font-size: 100%;

        }
        &__contactForm {
            font-size: 5em;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            //  padding: 1rem;
            min-width: 320px;
            max-width: 517px;
            height: 430px;
            border-radius: 1rem;
            margin-top: .5rem;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    
            &__bg {
                width: 100%;
                padding: 5%;
                height: 430px;
    
                p,
                h3 {
                    font-size: 1rem;
                    text-align: center;
                    margin-bottom: 1rem;
                    color: #6EBE4A;
    
                }
                p {
                    display: none;
                }
            }
    
        }
    
    }
    

    .btn-know-more {
        display: none;
    }

    .selectDonate {
        display: none;
    }
    .actions .hs-submit .hs_submit {
        display: flex !important;
        justify-content: center !important;
    }
    
}

@media (min-width: 665px) and (max-width: 991px) {
    .donations {
        &__typeDonation {
            flex-direction: column;
        }

        &__sectionTypes {
            width: 30%;
            padding: 0 .5px;

            h3,
            p {
                font-size: 100%;
            }
        }

        &__background {
            background-size: cover;
        }

    }

}


// @media (min-width: 992px) and (max-width: 1150px) {}