.donorProgress {
    font-family: 'Mulish', sans-serif;
    &__background {
        background-image:  linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('https://res.cloudinary.com/dd8l8bm6q/image/upload/v1689225653/ayapel/khi2n7p6nzszn7ng3n8t.jpg');
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        z-index: 3;
    }
    &__bar {
        border-radius: 40px;
    }

    &__container__progress {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1.8rem;
        gap: 2rem;
        background-color: #fff;
        border-radius: 77px;
        box-shadow: 0px 1px 0px hsl(0, 0%, 46%), /* Change the blue color to gray */
        0px 2px 0px hsl(0, 0%, 48%), /* Change the blue color to gray */
        0px 3px 0px hsl(0, 0%, 49%), /* Change the blue color to gray */
        0px 4px 0px hsl(0, 0%, 50%), /* Change the blue color to gray */
        0px 5px 0px hsl(0, 0%, 51%), /* Change the blue color to gray */
        0px 6px 0px hsl(0, 0%, 52%), /* Change the blue color to gray */
        0px 7px 0px hsl(0, 0%, 53%), /* Change the blue color to gray */
        0px 8px 0px hsl(0, 0%, 54%), /* Change the blue color to gray */
        0px 9px 0px hsl(0, 0%, 55%), /* Change the blue color to gray */
        0px 10px 0px hsl(0, 0%, 88%), /* Change the blue color to gray */
        0px 11px 10px #777779;
    }
    &__title {
        font-family: 'Mulish', sans-serif;
        margin-bottom: 3rem;
        color: #fff;
        font-size: 1.5rem;  
        text-align: center;
        margin-top: 5rem;
 
    }
    &__init, &__goal{
        font-family: 'Mulish', sans-serif;
       width: auto;
        font-size: 1.5rem;
    }
    &__goal {
        display: flex;
        flex-direction: row;
        width: auto;
        gap: 1rem;
        color: #777779;      
    }
    &__main {
        display: flex;
        flex-direction: column;
        padding-left: 15%;
        padding-right: 15%;
    }
    &__ctaDonations {
        display: flex;
        justify-content: center;
        margin-top: 5rem;
    }
    &__status {
        font-family: 'Mulish', sans-serif;
        text-align: center;
        margin-top: 2rem;
        font-size: 2rem;
        color: #fff;
    }
}

.imageAnimation {
    animation: slideIn 1s forwards; /* 1s es la duración de la animación. Puedes ajustarla según tu preferencia. */
}

@keyframes slideIn {
    from {
        left: 0;
    }
    to {
        left: 100%;
    }
}


// ********************MEDIA QUERY**************************
@media (max-width: 778px) {
    .donorProgress {
        &__init, &__goal{
            width: auto;
             font-size: .8rem;
         }
        &__title {
            font-size: 100%;
        }
        &__main {
            padding: 0;
            }

        &__container__progress {
            padding: 1rem;
         
            }
            &__status {
                text-align: center;
                margin-top: 2rem;
                font-size: 1rem;
            }
        }
}