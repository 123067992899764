@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

.carousel {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .carousel-slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0;
      transition: opacity 0.5s;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.25);
      }

      display: flex;
      align-items: center;
      justify-content: center;
      .right-down {
        position: relative;

        width: 43%;
        font-family: "Mulish", sans-serif;
        color: white;
        font-size: 36px;
        line-height: 40px;
        span {
          font-family: "Dancing Script", cursive;
          font-size: 60px;
        }

        position: absolute;
        margin-top: 88px;
        margin-left: 15%;

        top: 50%;
        left: 0;
        @media (max-width: 700px) {
          width: 66%;
          text-align: center;
        }
      }
      .right-align {
        position: relative;

        width: 43%;
        font-family: "Mulish", sans-serif;
        color: white;
        font-size: 36px;
        line-height: 40px;
        @media (max-width: 700px) {
          width: 66%;
          top: 80px;
          text-align: center;
        }
        span {
          font-family: "Dancing Script", cursive;
          font-size: 60px;
        }

        position: absolute;
        margin-top: 15%;
        margin-left: 15%;

        top: 0;
        left: 0;
      }
    }
    .carousel-slide.active {
      opacity: 1;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    .carousel-button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: white;
      margin: 0 5px;
      border: none;
      cursor: pointer;

      transform: scale(0.7);
    }

    .carousel-button.active {
      border: 1px solid white;
      background-color: transparent;

      transform: scale(1);
    }
  }
}
