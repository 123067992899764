.Footer {
  // background-image: url("https://res.cloudinary.com/dgnwqr93n/image/upload/v1689218720/Corpoayapel_julio_2016-52_vliuoi.jpg");

  // background-color: #6ebe4a;
  background-color: #f2f2f2;
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.436);
  }

  .main__Footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90vw;
    // justify-content: space-between;
    position: relative;
    padding-top: 130px;
    @media screen and (max-width: 1167px) {
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      gap: 10px;
      height: 90vh;
      justify-content: flex-start;
    }

    @media screen and (max-width: 910px) {
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      flex-direction: column-reverse;
      height: 80vh;
    }

    @media screen and (max-width: 450px) {
      height: 70vh;
      gap: 0px;
    }

  }
  &__Logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width: 500px) {
      display: none;
    }
    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }
    img {
      @media screen and (max-width: 910px) {
        width: 100px;
      }
      @media screen and (max-width: 1167px) {
        width: 250px;
        // display: none;
      }
      width: 350px;
      height: auto;
    }
  }
  &__colums {
    height: 235px;
    width: 500px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    font-family: "Mulish", sans-serif;
    @media screen and (max-width: 910px) {
      height: 110px;
    }

    @media screen and (max-width: 1167px) {
      height: 110px;
    }
    a {
      font-size: 16px;
      text-decoration: none;
      list-style: none;
      display: flex;
    }
    ul {
      display: flex;
      flex-direction: column;
    }
  }
  &__ul {
    gap: 10px;
    align-items: center;
    .bold {
      color: #6ebe4a;
    }
    a {
      display: flex;
      color: white;

      &:hover {
        color: #6ebe4a;
      }
    }
  }
  &__colums-content {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;
  }
  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      list-style: none;
      font-size: 16px;
      display: flex;
      gap: 20px;
      color: white;
      font-family: "Mulish", sans-serif;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      text-decoration: none;
      color: white;
      svg {
        color: #6ebe4a;
      }
    }
  }
  .bold {
    font-weight: 800;
    color: #707070;
  }
  &__Logo2 {
    display: none;
  }

}
textarea {
  resize: none;
  height: 200px;
  width: 100%;
  border: 1px solid green;
  border-radius: 5px;
  background-color: #e1e1e1;
}

@media screen and (max-width: 500px) {
  .Footer__Logo2 {
    display: block;
    transition: 0.5s;


    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }
    img {
      margin-top: -100px;
      width: 250px;
      height: auto;
    }

    .Footer__colums2 {
      height: 275px;
      width: 500px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      border-radius: 10px;
      font-family: "Mulish", sans-serif;

      a {
        font-size: 18px;
        text-decoration: none;
        list-style: none;
        display: flex;
        @media screen and (max-width: 400px) {
          font-size: 16px;
        }
      }
      ul {
        display: flex;
        flex-direction: column;
      }
    }
    .mainFooter__social2 {
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        list-style: none;
        font-size: 14px;
        display: flex;
        gap: 20px;
        color: white;
        font-family: "Mulish", sans-serif;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-decoration: none;
        color: white;
        svg {
          color: #6ebe4a;
        }
      }
    }
  }
}
