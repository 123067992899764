.mainSquareCard {
    // height: 60vh;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    // gap: 20px;
    padding: 0px 20px;
    // height: 100%;
    justify-content: center;
    position: relative;
    
    align-items: center;
    &__title {
      font-size: 16px;
      font-weight: 700;
      color: #535353;
      font-family: 'Mulish', sans-serif;
      padding: 10px 0px 0px 0px;
    }
    &__text {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Mulish', sans-serif;
      height: 125px;
      overflow: auto;
      color: #535353;
      overflow: hidden;
      margin-bottom: 15px;
    }
    &__img {
      z-index: 2;
      figure {
        img {
          border-radius: 20px 20px 0 0;
          width: 275px;
          height: 170px;
          object-fit: cover;
          border: 2.5px solid transparent;
          border-radius: 2px solid #ebf;
        }
      }
    }
    &__content {
      background-color: white;
      height: 100%;
      transform: translateY(-10px);
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 270px;
      padding: 5px 20px 10px 20px;
      gap: 5px;
      justify-content: flex-end;
    }
    &__gestion{
      h2{
          color: white;
          font-family: 'Mulish', sans-serif;
         
      }
    }
    &__buttons{
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      /* margin-top: 10px; */
      transform: translateX(-10px);
      // button{
      //   background-color: #47b94972;
      //   border: 0;
      //   border-radius: 5px;
      //   height: 30px;
      //   width: 30px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;

      // }
      
    }
    &__view{
     
      background-color: #47b94972;
      border: 0;
      border-radius: 5px;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      
      &:hover{
        cursor: pointer;
        color: #2e2e2e;
      }

    
  }
    &__share{
     
        background-color: #4799b972;
        border: 0;
        border-radius: 5px;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        &:hover{
          cursor: pointer;
          color: #2e2e2e;
        }
      
    }
  }

/**
  * Themeing
*/

.mainSquareCard__buttons {
  position: absolute;
}

.share-buttons {
  position: absolute;
  top: 10%;
  left: 80%;
  transform: translateX(220%);
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 27 !important;
  flex-direction: column;
  align-items: center;
  align-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  opacity: 0;
  transform: translateX(45px);
  transition: opacity 0.5s ease, transform 0.5s ease;

}



.share-buttons button {
  margin-right: 0px;
}

.share-buttons.show {
  opacity: 1; /* Change opacity to fully visible */
  transform: translateX(45px); /* Move the button up to its original position */
}

.share-buttons.fade-in {
  animation: fade-in 0.3s ease; /* Add fade-in animation */
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(0px); /* Move buttons up */

  }
  100% {
    opacity: 1;
    transform: translateX(45px); /* Move buttons to their original position */
  }
}