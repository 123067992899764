// * {
//     margin: 0;
//     box-sizing: border-box;
//   }
  
//   .containerDonation {
//     width: 100%;
//     height: 100vh;
//     scroll-snap-type: y mandatory;
//     overflow-y: scroll;
//     scrollbar-width: thin;
//     scrollbar-color: rgba(219, 69, 219, 0.8) white;
//   }
  
//   .containerDonation div {
//     scroll-snap-align: start;
//     width: 100%;
//     height: 100vh;
//     font-size: 5em;
//     font-family: sans-serif;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
  
//   .containerDonation div:nth-of-type(even) {
//     background-color: #fff;
//     color: white;
//     height: 100vh;
//   }
  
//   /* Scrollbar modification */
//   .containerDonation::-webkit-scrollbar {
//     width: 0px;
//   }
  
//   .containerDonation::-webkit-scrollbar-track {
//     background-color: transparent;
//   }
  
//   .containerDonation::-webkit-scrollbar-thumb {
//     background-color: transparent;
//   }
  
//   .containerDonation::-webkit-scrollbar-thumb:hover {
//     background-color: transparent;
//   }
  

//   .mainNav{
  
//     padding-top: 0 !important;
//    &__nav{
      
//         background-color: #6EBE4A !important;
//        ul{
//            display: flex;
//            list-style-type: none;
//            align-items: center;
//            justify-content: space-evenly;
//            color: white;
//            font-weight: 600;
//            font-family: 'Mulish', sans-serif;
           
//         }
//     }
// }

* {
  margin: 0;
  box-sizing: border-box;
}

.container__mainDonations {
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.container__mainDonations .div {
  scroll-snap-align: start;
  width: 100%;
  height: 100vh;
 // font-size: 5em;
  font-family: sans-serif;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  z-index: -8;
}

.container__mainDonations .div:nth-of-type(even) {
  background-color: #fff;
  color: white;
  height: 100vh;
}

/* Scrollbar modification */
.container__mainDonations::-webkit-scrollbar {
  width: 0px;
}

.container__mainDonations::-webkit-scrollbar-track {
  background-color: transparent;
}

.container__mainDonations::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.container__mainDonations::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.hubspot-link__container sproket{
  display: none !important;
  visibility: hidden !important;
}

.donations__mobile {
  display: none;
}

@media (max-width: 664px) {
  .donations__mobile {
    display: flex;
  }
}