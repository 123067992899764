.ayapel {
    &__background {
        //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dgnwqr93n/image/upload/v1688153550/slide-1_fgfpri.jpg');
        //background-color: #6ebe4a;
        height: 100vh;
        width: 100%;
       display: flex;
        background-size: cover;
        z-index: .5;
        flex-wrap: wrap;
    }
    &__container {
        display: flex;
        width: 100%;
        flex-direction: column;
        &__nav {
            display: flex;
            justify-content: flex-end;
            margin-top: 120px;

        }
        &__ppl {
            display: flex;
            margin-top: 2rem;
            flex-wrap: wrap;
        }
        &__columnLeft, &__columnRight {
            font-family: 'Mulish', sans-serif;
            color: #fff;
            width: auto;
            display: flex;
            justify-content: flex-start;
            font-size: 100%;
            width: 60%;
        }
        &__columnLeft {
            width: 40%;
            flex-grow: 1;
        }

    }
}