.background{
    // background-image: url('https://res.cloudinary.com/dgnwqr93n/image/upload/q_100/v1689218731/_MG_9667_eujqjn.webp');
    // background-color: #6ebe4a;
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    
}
.background::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.436);
}
.mainHome{
    display: flex;
    flex-direction: row;
    font-size: 18px;
    align-items: center;
    padding: 0px 80px;
    width: 100vw;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width:1068px) {
        align-items: center;
        justify-content: center;
        gap: 50px;
    }
    &__button{
        display: flex;
        position: relative;
    align-items: center;
    justify-content: center;
    }
   
    &__text{
        position: relative;
        color: white;  
        font-size: 3.5rem;
        line-height: 1;
        text-align: left;
       
        
        font-family: 'Mulish', sans-serif;
        @media screen and (max-width:1068px) {
            text-align: center;
        }
        @media screen and (max-width:535px) {
            font-size: 2rem;
            text-align: center;
        }
        p{

            width: 55vw;
            height:45%;
           @media screen and (max-width:535px) {
            width: 80vw; 
           }

           @media screen and (max-width:910px) {
            width: 80vw;
           }

        }
    }
}
.btn-bottom{
        top:26vh;
        display: flex;
        // position: relative;
        right: 35px;
        align-items: center;
        justify-content: center;
        position: unset;
        @media screen and (max-width:1068px) {
            align-items: center;
            justify-content: center;
            position: unset;
          }
}

// .palmas{
//     top: 0;
//     position: absolute;
//     img{
//         width: 100vw;
//     }
// }

