.mainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  justify-content: space-between;
  position: relative;
  padding-top: 130px;
  @media screen and (max-width:1167px) {
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    padding-top: 20px;
    gap: 10px;
    height: 90vh;
    justify-content: flex-start;
  }

  @media screen and (max-width:910px) {
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    padding-top: 20px;
    flex-direction: column-reverse;
    // gap: 50px;
    height: 80vh;
  }

  @media screen and (max-width:450px) {
   height: 70vh;
   gap: 0px;
  }
  

 
  &__logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width:450px) {
      display: none;
    }
    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }
    img {
      @media screen and (max-width:910px) {
        width: 100px;
      }
      @media screen and (max-width:1167px) {
        width: 150px;
        display: none;
      }
      width: 350px;
      height: auto;
    }
  }
  &__form {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    color: #757575;
    background-color: white;
    border-radius: 5px 5px;
    height: 70vh;
    width: 550px;
    padding: 0px 30px;
    @media screen and (max-width:1167px) {
      height: 45vh;
    }
    @media screen and (max-width:910px) {
       width: 90%;
      height: 70vh;
    }
    @media screen and (max-width:425px) {
      width: 100%;
      height: 100%;
    }

   
    
    section{
      div{
        scroll-snap-align: start;
        width: 100%;
        font-size: 5em;
        font-family: sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75vh !important;
        @media screen and (max-width:910px) {
          height: 33vh !important;
        }
        @media screen and (max-width:1167px) {
          height: 70vh !important;
        }
        @media screen and (max-width:425px) {
          height: 80vh !important;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      input {
        height: 30px;
        border-bottom: 1px solid green;
        background-color: #e1e1e1;
        padding: 0px 10px;
      }
    }
  }
  &__form-input {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    padding-bottom: 10px;
    font-weight: 400;
    font-family: "Mulish", sans-serif;
    gap: 5px;
    input {
      font-family: "Mulish", sans-serif;
      // border-radius: 10px;
      border: 0px;
    }
  }
  &__form-title {
    font-size: 1.5rem;
    color: #6ebe4a;
    span {
      color: #757575;
      font-size: 0.95rem;
    }
  }
  &__form-submit {
    button {
      background-color: #6fbe4a71;
      border-radius: 5px;
      color: #549138;
      padding: 5px 10px;
      border: 1px transparent;
    }
  }

  &__colums {
    height: 235px;
    width: 500px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    font-family: 'Mulish', sans-serif;
    @media screen and (max-width:910px) {
      height: 110px;
    }
    
    @media screen and (max-width:1167px) {
      height: 110px;
    }
    a {
      font-size: 16px;
      text-decoration: none;
      list-style: none;
      display: flex;
    }
    ul {
      display: flex;
      flex-direction: column;
    }
  }
  &__ul {
    gap: 10px;
    align-items: center;
    .bold{
        color: #6ebe4a;
    }
a {
      display: flex;
    color: white;
    
    &:hover{
        color: #6ebe4a;
    }
   
    }
  }
  &__colums-content {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;
  }
  &__social{
    display: flex;
    align-items: center;
    justify-content: center;
    ul{
        list-style: none;
        font-size: 16px;
        display: flex;
        gap: 20px;
        color: white;
        font-family: 'Mulish', sans-serif;
    }
    a{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-decoration: none;
        color: white;
        svg{
            color: #6ebe4a;
        }
    }

  }
}

.bold{
    font-weight: 800;
    color: #707070;
}
.background-5 {
  background-image: url("https://res.cloudinary.com/dgnwqr93n/image/upload/v1689218720/Corpoayapel_julio_2016-52_vliuoi.jpg");
  
  // background-color: #6ebe4a;
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}
.background-5::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.436);
}
textarea {
  resize: none;
  height: 200px;
  width: 100%;
  border: 1px solid green;
  border-radius: 5px;
  background-color: #e1e1e1;
}
